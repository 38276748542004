import React from 'react';

import { OverflowProvider } from './src/providers/OverflowProvider';
import { ModalProvider } from './src/providers/ModalProvider';
import { QueryProvider } from './src/providers/QueryProvider';

export const wrapRootElement = ({ element }) => (
  <ModalProvider>
    <OverflowProvider>
      <QueryProvider>
        {element}
      </QueryProvider>
    </OverflowProvider>
  </ModalProvider>
)

//Force browser to scroll to top on page change
export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)

  return false;
}

export const onRouteUpdate = () => {
  //Remove the lead anywhere script when navigating to non-B2B pages
  let tags = document.querySelectorAll('.leadanywhere-tag');
  if(tags.length > 0){
    tags.forEach((e, i) => {
      if(i > 0){
        e.remove()
      }
    });
  }

  let scripts = document.querySelectorAll('.leadanywhere')
  if(scripts.length > 0){
    scripts.forEach((e, i) => {
      if(i > 0){
        e.remove()
      }
    });
  }
}