import React, { useState } from 'react'

import QueryContext from '../context/QueryContext';

export const QueryProvider = ({ children }) => {
  const [query, setQuery] = useState();

  function handleGetQuery(queryString) {
    if (queryString) {
      setQuery(queryString);
    }
  }

  function handleClearQuery() {
    setQuery();
  }

  return (
    <QueryContext.Provider
      value={{ handleGetQuery, handleClearQuery, query }}
    >
      {children}
    </QueryContext.Provider>
  )
}